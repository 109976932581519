<template>
  <div>
    <b-tabs
      pills
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="MessageSquareIcon" />
          <span>Görüşme Notu</span>
        </template>
        <create />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="UsersIcon" />
          <span>Müşteri Geçmişi</span>
        </template>
        <customer-view />
      </b-tab>
    </b-tabs>
    <template v-if="['2','3','6','7','9','27'].includes(userData.id_com_department) || ['1','2','3','4','5','6','7','8','9','13','14'].includes(userData.id_com_brand)">
      <kvkk-modal
        v-if="interview.id_com_customer"
        :customer-id="interview.id_com_customer"
        :modal-show="Number(customer.kvkk) !== 1"
        :refresh-data="getCustomer"
      />
    </template>
  </div>
</template>
<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import Create from '@/views/Interviews/components/Create.vue'
import CustomerView from '@/views/Customers/CustomerView.vue'
import KvkkModal from '@/layouts/components/common/KvkkModal.vue'

export default {
  name: 'InterviewAdd',
  components: {
    BTabs,
    BTab,
    Create,
    CustomerView,
    KvkkModal,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
    interview() {
      return this.$store.getters['interviews/getInterview']
    },
    saveStatus() {
      return this.$store.getters['interviews/getInterviewSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
      this.interview.submitStatus = false
    },
  },
  created() {
    this.$store.dispatch('interviews/interviewReset')
    this.getInterviewNumber()
    this.interview.id_com_customer = this.$route.params.id
    this.interview.idate = this.moment().format('YYYY-MM-DD')
    this.interview.itime = this.moment().format('LT')
  },
  methods: {
    getCustomer() {
      if (this.$route.params.id) {
        this.$store.dispatch('customers/customerView', this.$route.params.id)
      }
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      }).then(resoponse => {
        if (resoponse.isConfirmed) {
          this.$router.push(`/interviews/view/${this.interview.interview_number}`)
        }
      })
    },
    getInterviewNumber() {
      this.$store.dispatch('interviews/getInterviewNumber')
    },
  },
}
</script>
