<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-row>
        <b-col cols="4">
          <b-card title="İkinci El Görüşmesi">
            <meets />
            <interview-subjects />
            <div v-if="interview.id_com_interview_subject === '35'">
              <swap-brands />
              <swap-models />
            </div>
            <div v-else>
              <i2-stocks />
            </div>
          </b-card>
        </b-col>
        <b-col>
          <b-card title="Görüşme Detay">
            <interview-statuses
              v-if="interview.id_com_interview_type"
              :id_com_interview_type="interview.id_com_interview_type"
              first-status="1"
            />
            <interview-content />
            <new-alert />
            <save-button :action-methods="submitForm" />
          </b-card>
        </b-col>
      </b-row>
    </validation-observer>
    <add-car />
  </div>
</template>

<script>
import { ValidationObserver, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'
import Meets from '@/views/Interviews/forms/Meets.vue'
import InterviewStatuses from '@/views/Interviews/forms/Interview_statuses.vue'
import InterviewContent from '@/views/Interviews/forms/Content.vue'
import NewAlert from '@/views/Interviews/forms/NewAlert.vue'
import SaveButton from '@/views/Interviews/forms/SaveButton.vue'
import AddCar from '@/views/Interviews/components/AddCar.vue'
import InterviewSubjects from '@/views/Interviews/forms/InterviewSubjects.vue'
import i2Stocks from '@/views/Interviews/forms/i2Stocks.vue'
import SwapBrands from '@/views/Interviews/forms/Swap_brands.vue'
import SwapModels from '@/views/Interviews/forms/Swap_models.vue'

export default {
  name: 'SecondHand',
  components: {
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    Meets,
    InterviewStatuses,
    InterviewContent,
    NewAlert,
    AddCar,
    InterviewSubjects,
    i2Stocks,
    SwapBrands,
    SwapModels,
    SaveButton,
  },
  computed: {
    interview() {
      return this.$store.getters['interviews/getInterview']
    },
  },
  created() {
    localize('tr')
    this.interview.initial = '1'
    this.interview.id_com_interview_status = '14'
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.interview.submitStatus = true
          this.$store.dispatch('interviews/interviewSave', this.interview)
        }
      })
    },
  },
}
</script>

<style scoped></style>
