<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-row>
        <b-col cols="4">
          <b-card title="Sigorta Görüşmesi">
            <meets />
            <interview-subjects />
            <chasis v-if="['23','24','26','27','33'].includes(interview.id_com_interview_subject)" />
            <policy v-else />
          </b-card>
        </b-col>
        <b-col>
          <b-card title="Görüşme Detay">
            <!--            <date-time />-->
            <interview-statuses
              v-if="interview.id_com_interview_type"
              :id_com_interview_type="interview.id_com_interview_type"
              first-status="1"
            />
            <interview-content />
            <new-alert />
            <save-button :action-methods="submitForm" />
          </b-card>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'
import Meets from '@/views/Interviews/forms/Meets.vue'
import InterviewStatuses from '@/views/Interviews/forms/Interview_statuses.vue'
import InterviewContent from '@/views/Interviews/forms/Content.vue'
import NewAlert from '@/views/Interviews/forms/NewAlert.vue'
import SaveButton from '@/views/Interviews/forms/SaveButton.vue'
import Policy from '@/views/Interviews/forms/Policy.vue'
import Chasis from '@/views/Interviews/forms/Chasis.vue'
import InterviewSubjects from '@/views/Interviews/forms/InterviewSubjects.vue'
// import DateTime from '@/views/Interviews/forms/DateTime.vue'

export default {
  name: 'Insurance',
  components: {
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    Meets,
    InterviewStatuses,
    InterviewContent,
    NewAlert,
    Chasis,
    Policy,
    InterviewSubjects,
    // DateTime,
    SaveButton,
  },
  computed: {
    interview() {
      return this.$store.getters['interviews/getInterview']
    },
  },
  created() {
    localize('tr')
    this.interview.initial = '1'
    this.interview.id_com_interview_status = '17'
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.interview.submitStatus = true
          this.$store.dispatch('interviews/interviewSave', this.interview)
        }
      })
    },
  },
}
</script>
